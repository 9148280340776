















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class CheckboxCircle extends Vue {
  @Watch('value', { immediate: true }) valueChange(value) {
    this.modelValue = value
  }
  @Prop({ default: false }) value!: boolean
  @Prop({ default: false }) disabled!: boolean
  modelValue = false
  onStatusChange(val: boolean) {
    this.$emit('onStatusChange', val)
  }
}
